import React from "react"
import Navigation from "./navigation"
import HeaderLogo from "./header-logo"
import Row from "../layout/row"
import styled from "@emotion/styled"

const HeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  border-bottom: 1px solid ${props => props.theme.colors.line};
  padding-bottom: 0.5rem;
  margin: 0.7rem auto 0;
  ${props => props.theme.tablet} {
    margin: 0 auto;
    max-width: 90vw;
    padding: 0.5rem 0;
    border-bottom: none;
    
  }
`

const HeaderWrapper = styled.div`
  ${props => props.theme.tablet} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    background-color: rgb(255,255,255,0.9);

  }
`

const Header = () => {
  return (
    <div>
      <Row>
        <HeaderWrapper>
          <HeaderStyled>
            <HeaderLogo />
            <Navigation />
          </HeaderStyled>
        </HeaderWrapper>
      </Row>
    </div>
  )
}

export default Header
