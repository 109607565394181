import React from "react"
import { css } from "@emotion/core"

const Row = ({ children, margin, className }) => {
  const calcMargin = margin ? "1.5rem auto" : "0 auto"
  return (
    <div
      className={className}
      css={css`
        max-width: 90vw;
        margin: ${calcMargin};
        width: 1280px;
      `}
    >
      {children}
    </div>
  )
}

export default Row
