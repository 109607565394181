import React from "react"

import styled from "@emotion/styled"

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.tablet} {
    margin-bottom: 1.5rem;
  }
`


const InfoStyled = styled.div`
  padding-bottom: 0.1rem;
  margin-bottom: 0.3rem;
  opacity: 0.8;
  a {
    &:hover {
      opacity: 1;
      border-bottom: 1px dashed white;
    }
  }
`

const HeaderWrapper = styled.div`
  margin-bottom: 0.5rem;
`

const FooterInfo = ({ title, info}) => {
  return (
    <InfoWrapper>
      <HeaderWrapper>
        {title}
      </HeaderWrapper>
      <InfoStyled dangerouslySetInnerHTML={{ __html: info }} />
    </InfoWrapper>
  )
}

export default FooterInfo
