import React from 'react'
import { Link } from "gatsby"
import styled from "@emotion/styled"

const ActiveLink = props => <Link {...props} activeClassName="current-page" />

const NavLink = styled(ActiveLink)`
  display: block;
  color: ${props => props.theme.colors.navLink};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.75rem 0.25rem;
  text-decoration: none;
  text-transform: uppercase;

  &.current-page {
    color: ${props => props.theme.colors.activeLink};
  }

  &:hover {
    color: ${props => props.theme.colors.hoverLink};
  }

  &:last-of-type {
    margin-right: 0px;
  }
`

export default NavLink