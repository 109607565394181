import React from "react"
import Helmet from "react-helmet"
import useSiteMetadata from "../../hooks/use-sitemetadata"

const Meta = () => {
  const { title, description } = useSiteMetadata()
  return (
    <Helmet>
      <html lang="ru" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  )
}
export default Meta
