import React from "react"
import { Link } from "gatsby"
import LogoForHeader from "../../assets/logo_full.svg"
import styled from "@emotion/styled"
import LogoMobile from "../../assets/logo_small.svg"

const LogoForHeaderSt = styled(LogoForHeader)`
  height: 100%;
  width: 20rem;
  ${props => props.theme.tablet} {
    display: none;
  }
`

const LogoMobileSt = styled(LogoMobile)`
  height: 100%;
  width: 3rem;
  display: none;
  ${props => props.theme.tablet} {
    display: block;
  }
`

const HeaderLogo = () => {
  return (
    <Link
      to="/"
      title="Главная страница, Фонд Защиты Прав Граждан участников строительства в ПК"
    >
      <LogoForHeaderSt />
      <LogoMobileSt />
    </Link>
  )
}

export default HeaderLogo
