import React from "react"
import { css } from "@emotion/core"
import Header from "../header"
import Footer from "../footer"
import GlobalStyles from "./global"
import Meta from "./meta"
import styled from "@emotion/styled"


const Main = styled.main`
  flex-grow: 1;
  ${props => props.theme.tablet} {
    margin-top: 5rem;
  }
`

const Layout = ({ children }) => {

  return (
    <>
      <GlobalStyles />
      <Meta />
      <article
        css={css`
          margin: 0rem auto;
          display: flex;
          flex-direction: column;
          min-height: 101vh;
          align-items: stretch;
          h1 {
            padding-bottom: 1.5rem;
          }
        `}
      >
        <Header />
        <Main>{children}</Main>
        <Footer />
      </article>
    </>
  )
}

export default Layout
