import React, { useState } from "react"
import styled from "@emotion/styled"
import NavLink from "./link"
import CloseIcon from "../../assets/close.svg"

const NavigationWrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;

  ${props => props.theme.tablet} {
    position: relative;
    top: 25%; /* 25% from the top */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
    display: block;
  }
`

const CloseButton = styled.button`
  display: none;
  ${props => props.theme.tablet} {
    display: block;
    position: absolute;
    top: 35px;
    right: 35px;
    color: ${props => props.theme.colors.footerFont};
    background-color: transparent;
    border-style: none;
  }
`

const Overlay = styled.div`
  ${props => props.theme.tablet} {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: ${props => (props.mobileOpen ? "0" : "100%")};
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    background-color: ${props =>
      props.theme.colors
        .footer}; /* Black fallback color */ /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
    ${NavLink} {
      white-space: nowrap;
      display: ${props => (props.mobileOpen ? "none" : "block")};
      padding: 1rem;
      font-weight: 500;
      text-decoration: none;
      font-size: 1.2rem;
      color: ${props => props.theme.colors.footerFont};
      transition: opacity 5s ease-in-out;
    }
  }
`

const IconBar = styled.div`
  width: 1.5rem;
  height: 2px;
  background-color: black;
  margin: 0.3rem 0;
  opacity: 0.8;
`

const Icon = styled.div`
  display: inline-block;
  cursor: pointer;
  display: none;
  ${props => props.theme.tablet} {
    display: block;
  }
`
const Navigation = props => {
  const [isMenuOpen, handleMenu] = useState(true)

  function handleBarClick() {
    handleMenu(isMenuOpen => !isMenuOpen)
  }
  return (
    <>
      <Overlay mobileOpen={isMenuOpen}>
        <CloseButton onClick={handleBarClick}>
          <CloseIcon />
        </CloseButton>
        <NavigationWrapper>
          <NavLink to="/">Главная</NavLink>
          <NavLink partiallyActive={true} to="/about/">
            О фонде
          </NavLink>
          <NavLink partiallyActive={true} to="/holders/">
            Дольщикам
          </NavLink>
          <NavLink partiallyActive={true} to="/objects/in-work/">
            Объекты
          </NavLink>
          <NavLink partiallyActive={true} to="/apartments/">
            Продажи
          </NavLink>
          <NavLink partiallyActive={true} to="/news/">
            Новости
          </NavLink>
          <NavLink partiallyActive={true} to="/contacts/">
            Контакты
          </NavLink>
        </NavigationWrapper>
      </Overlay>
      <Icon onClick={handleBarClick}>
        <IconBar></IconBar>
        <IconBar></IconBar>
        <IconBar></IconBar>
      </Icon>
    </>
  )
}

Navigation.propTypes = {}

export default Navigation
