import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import LogoForFooter from "../../assets/drawing.svg"
import DomRfLogo from "../../assets/dom.svg"
import PermKrai from "../../assets/permkrai.svg"
import { css } from "@emotion/core"

const LogosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px dashed rgba(255, 255, 255, 0.3);
  padding-right: 5rem;

  ${props => props.theme.tablet} {
    margin-bottom: 1rem;
    border-right: none;
    padding-right: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
  }
`

const logosSize = css`
  height: 100%;
  width: 3rem;
`

const rounded = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
`

const hover = css`
  &:hover {
    span {
      opacity: 1;
    }
  }
`


const IndexLink = styled(Link)`
  ${rounded}
  ${hover}
`

const LogoWrapper = styled.div`
  padding-bottom: 1.5rem;
`

const Logo = styled.div`
  padding-right: 1rem;
`

const LogoLink = styled.a`
  ${rounded}
  ${hover}
`
const LogoAnnotation = styled.span`
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.2;
  opacity: 0.9;
`

const FooterLogo = () => {
  return (
    <LogosWrapper>
      <LogoWrapper>
        <IndexLink to="/">
          <Logo>
            <LogoForFooter
              css={css`
                ${logosSize}
                path {
                  fill: white;
                }
              `}
            />
          </Logo>
          <LogoAnnotation>
            Фонд защиты прав граждан&ndash;
            <br /> участников строительства ПК
          </LogoAnnotation>
        </IndexLink>
      </LogoWrapper>
      <LogoWrapper>
        <LogoLink
          href="https://xn--214-mdd8bf5b.xn--p1ai/"
          target="_blank"
          rel="noreferrer"
        >
          <Logo>
            <DomRfLogo
              css={css`
                ${logosSize}
                g {
                  fill: white;
                }
              `}
            />
          </Logo>
          <LogoAnnotation>
            Фонд защиты прав граждан&ndash; <br /> участников долевого
            строительства
          </LogoAnnotation>
        </LogoLink>
      </LogoWrapper>
      <div>
        <LogoLink
          href="http://ms.permkrai.ru/ "
          rel="noreferrer"
          target="_blank"
        >
          <Logo>
            <PermKrai
              css={css`
                ${logosSize}
              `}
            />
          </Logo>
          <LogoAnnotation>
            Министерство строительства <br /> Пермского края
          </LogoAnnotation>
        </LogoLink>
      </div>
    </LogosWrapper>
  )
}

export default FooterLogo
