export function toLocalDate(date) {
  return new Date(date).toLocaleDateString("ru-Ru", {
    month: "long",
    day: "numeric",
    year: "numeric",
  })
}

export function replaceToComa(str) {
  if (!isNaN(parseFloat(str)) && typeof str === "string") {
    return str.replace(/;|,/g, ".")
  }
  if (typeof str === "string") {
    return str.replace(/;/g, ",")
  }
  return str
}

export function getElementsByClassName(html, classname) {
  const div = document.createElement("div")
  div.innerHTML = html
  if (div.getElementsByClassName(classname).length > 0) {
    return div.getElementsByClassName(classname)[0].innerHTML
  }
  return ""
}

export function addQueryParam(key, value) {
  if (typeof window !== `undefined`) {
    const url = new URL(window.location.href)
    url.searchParams.set(key, value)
    window.history.pushState({}, "", url.toString())
  }
}

export function stateFromQueryParam(key, defaultValue) {
  if (typeof window !== `undefined`) {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get(key)) {
      return queryParams.get(key)
    }
  }
  return defaultValue
}

export function deleteFromQueryParam(key) {
  if (typeof window !== `undefined`) {
    const url = new URL(window.location.href)
    url.searchParams.delete(key)
    window.history.pushState({}, "", url.toString())
  }
}

export function scrollToTop() {
  if (typeof window !== `undefined`) {
    window.requestAnimationFrame(() => {
      window.scrollTo(0, 0)
    })
  }
}

export function parseApartmentsByOnlyFloor(apartments) {
  return apartments.reduce((accumulator, currentValue) => {
    if (!accumulator[currentValue.floor]) {
      return {
        ...accumulator,
        [currentValue.floor]: {
          [currentValue.entrance]: [currentValue],
        },
      }
    }

    if (!accumulator[currentValue.floor][currentValue.entrance]) {
      const newValue = {
        ...accumulator,
        [currentValue.floor]: {
          ...accumulator[currentValue.floor],
          [currentValue.entrance]: [currentValue],
        },
      }
      return newValue
    }

    return {
      ...accumulator,
      [currentValue.floor]: {
        ...accumulator[currentValue.floor],
        [currentValue.entrance]: [
          ...accumulator[currentValue.floor][[currentValue.entrance]],
          currentValue,
        ],
      },
    }
  }, {})
}

export function fillEmptyFloors(apartments) {
  let totalFloors = 0

  apartments.forEach(entrance => {
    if (totalFloors < entrance.length) {
      totalFloors = entrance.length
    }
    const entranceNumber = entrance[0]?.[0]?.entrance
    const indexToAdd = []

    const searchFor = entrance => {
      entrance.forEach((floor, indexOfFloor, entrance) => {
        const nextFloorInArray = entrance[indexOfFloor + 1]?.[0]?.floor
        if (
          floor[0].floor + 1 !== nextFloorInArray &&
          typeof nextFloorInArray !== "undefined"
        ) {
          const currentFloorInArray = floor[0]?.floor
          const nextFloorInArray = entrance[indexOfFloor + 1]?.[0]?.floor

          function range(start, end) {
            return Array(end - start + 1)
              .fill()
              .map((_, idx) => start + idx)
          }

          range(currentFloorInArray + 1, nextFloorInArray - 1).forEach(
            (element, index) => {
              indexToAdd.push({
                index: indexOfFloor + 1 + index,
                floor: element,
              })
            }
          )
        }
      })
    }

    searchFor(entrance)

    indexToAdd.forEach(index => {
      entrance.splice(
        index.index,
        0,
        new Array(entrance[0].length).fill({
          filler: true,
          entrance: entranceNumber,
          floor: index.floor,
        })
      )
    })
  })

  return apartments.reduce((accumulator, entrance, index) => {
    let fillerRowsAtEnd = []
    let fillerRowsAtStart = []
    if (entrance[0]?.[0]?.floor > 1) {
      const entranceNumber = entrance[0]?.[0]?.entrance || index + 1
      fillerRowsAtStart = new Array(entrance[0]?.[0]?.floor - 1).fill(
        new Array(entrance[0].length).fill({
          filler: true,
          entrance: entranceNumber,
        })
      )
    }
    if (entrance.length < totalFloors) {
      const numberOfAddedRowAtStart = fillerRowsAtStart
        ? fillerRowsAtStart.length
        : 0
      fillerRowsAtEnd = new Array(
        totalFloors - entrance.length - numberOfAddedRowAtStart
      ).fill(
        new Array(entrance[0].length).fill({
          filler: true,
          entrance: entrance[0]?.[0]?.entrance,
        })
      )
    }

    return [
      ...accumulator,
      (entrance = [...fillerRowsAtStart, ...entrance, ...fillerRowsAtEnd]),
    ]
  }, [])
}

export function parseApartmentsByFloor(apartments) {
  return apartments.reduce((accumulator, currentValue) => {
    if (!accumulator[currentValue.floor]) {
      return {
        ...accumulator,
        [currentValue.floor]: [currentValue],
      }
    }

    return {
      ...accumulator,
      [currentValue.floor]: [...accumulator[currentValue.floor], currentValue],
    }
  }, {})
}

export function parseApartmentsByEntrace(apartments) {
  const apartmentsByEntrance = apartments.reduce(
    (accumulator, currentValue) => {
      if (!accumulator[currentValue.entrance]) {
        return {
          ...accumulator,
          [currentValue.entrance]: [currentValue],
        }
      }

      return {
        ...accumulator,
        [currentValue.entrance]: [
          ...accumulator[currentValue.entrance],
          currentValue,
        ],
      }
    },
    {}
  )
  return Object.values(apartmentsByEntrance)
}
