import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import FooterLogo from "./footer-logo"
import Info from "./info"
import Row from "../layout/row"
import { Link } from "gatsby"
import useContacts from "../../hooks/use-contacts"
import { getElementsByClassName } from "../../helpers"

const Wrapper = styled.div`
  background: ${props => props.theme.colors.footer};
`

const Heading = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
`

const Question = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
  &:hover {
    border-bottom: 1px dashed white;
  }
`

const FooterWrapper = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin: 0rem auto;
  color: ${props => props.theme.colors.footerFont};
  padding: 2rem 0;
  min-height: 25vh;
  h4 {
    color: ${props => props.theme.colors.footerHeading};
    font-size: 1.5rem;
    font-weight: 400;
  }

  a {
    color: ${props => props.theme.colors.footerFont};
  }

  ${props => props.theme.tablet} {
    flex-direction: column;
    align-items: start;
    padding: 2rem 0rem;
  }
`
const Footer = () => {
  const data = useContacts()
  const [address, setAdress] = useState("")
  const [contacts, setContacts] = useState("")

  useEffect(() => {
    setAdress(getElementsByClassName(data.content, "address"))
    setContacts(getElementsByClassName(data.content, "contacts"))
  }, [data.content])

  return (
    <Wrapper>
      <Row>
        <FooterWrapper>
          <FooterLogo />
          <Info
            title={
              <Link to="/contacts/">
                <Heading>Контакты</Heading>
              </Link>
            }
            info={contacts}
          />
          <Info
            title={
              <Link to="/contacts/">
                <Heading>Адрес</Heading>
              </Link>
            }
            info={address}
          />
          <Link to="/contact-us/">
            <Question>Задать вопрос</Question>
          </Link>
        </FooterWrapper>
      </Row>
    </Wrapper>
  )
}

export default Footer
